.navBar{
  position:fixed;
  display: flex;
  font-family: 'noto-sans-kr-regular';
  /* font */
  color: #A6A6A6;
  text-align: center;
  font-size: 15px;
  /* border: 1px solid gray; */
  top: 0;
  left: 50%;
  right: 0;
  width: 600px;
  transform: translateX( -50% );
  /* height: 20px; */
  padding-top: 52px;
  background-color: white;
  z-index: 2;
}

.heightControl {
  height: 40px;
}

.navMain {
flex-grow:1;
flex-shrink: 0;
padding-bottom: 15px;
/* border: 1px solid gray; */
}

.navMain:hover {
  color: #000000;
  cursor: pointer;
  }

.navMomentum {
  flex-grow:1;
  flex-shrink: 0;
  /* border: 1px solid gray; */
}

.navMomentum:hover {
  color: #000000;
  cursor: pointer;
  }

.navStable {
  flex-grow:1;
  flex-shrink: 0;
  /* border: 1px solid gray; */
}

.navStable:hover {
  color: #000000;
  cursor: pointer;
  }

.navContact {
  flex-grow:1;
  flex-shrink: 0;
  /* border: 1px solid gray; */
}

.navContact:hover {
  color: #000000;
  cursor: pointer;
  }

@media (max-width:600px) {

    .navBar {
      width: auto;
      left:0;
      transform: initial;
    }
}