 /* 공통 페이지 레이아웃 */

body {
  margin: 0;
  /* overflow-y: hidden; */
}

.mainpageMain{
/* padding-top:60px;  */
}

.mainUpper {
  /* line-height: 120px; */
}

.mainTitle{
  font-size: 40px;
  /* line-height:70px; */
  /* font-size: 96px; */
  /* ppt fontsize x 1.3 x 4/3 = px */
  /* padding-top: 50px; */
  text-align: center;
  font-family: 'noto-sans-kr-bold';
  line-height: 70px;
}

.mainSubTitleMobile{
  font-size: 29px;
  text-align: center;
  font-family: 'noto-sans-kr-bold';
  display: none;
  text-align: center;
  padding-bottom: 10px;
}

.mainSubTitleWeb{
  font-size: 29px;
  text-align: center;
  font-family: 'noto-sans-kr-bold';
  display:block;
  text-align: center;
}

.mainDescription1{
  font-size: 21.3px;
  font-family: 'noto-sans-kr-light';
  color: #A6A6A6;
  text-align: center;

}

.mainDescription2{
  font-size: 21.3px;
  font-family: 'noto-sans-kr-light';
  color: #A6A6A6;
  text-align: center;

}

.mainDescription3{
  font-size: 21.3px;
  font-family: 'noto-sans-kr-light';
  color: #A6A6A6;
  text-align: center;
}

/* .mainpageMain {
  height: 400px;
} */

@media (prefers-reduced-motion: no-preference){
  .mainUpper{
    animation: bannerAppear 1.8s ease-out;
  }
}
  
@keyframes bannerAppear {
  0% { margin-top:53px; opacity: 0%; }
  30% {margin-top:53px; opacity:0%}
  70% { margin-top:53px; opacity:100% }
  100% { margin-top : 0px; }
}

@media (prefers-reduced-motion: no-preference){
  .mainDescription1{
    animation: description1Appear 1.4s ease-out;
  }
}

@media (prefers-reduced-motion: no-preference){
  .mainDescription2{
    animation: description1Appear 1.9s ease-out;
  }
}

@media (prefers-reduced-motion: no-preference){
  .mainDescription3{
    animation: description1Appear 2.4s ease-out;
  }
}

@media (prefers-reduced-motion: no-preference){
  .navBar{
    animation: description1Appear 2.9s ease-out;
  }
}

 @keyframes bannerAppear {
  0% { margin-top:53px; opacity: 0%; }
  30% {margin-top:53px; opacity:0%}
  70% { margin-top:53px; opacity:100% }
  100% { margin-top : 0px; }
}

@keyframes description1Appear {
  0% { opacity: 0%; }        
  90% { opacity: 0%; }
  100% { opacity: 100% }
}

@keyframes description2Appear {
  0% { opacity: 0%; }
  73% { opacity: 0%; }
  100% { opacity: 100% }                 
}

@keyframes description3Appear {
  0% { opacity: 0%; }
  79% { opacity: 0%; }
  100% { opacity: 100% }
}

/* crypto asset management labs 모바일 두줄 랩탑 한줄 */
@media(max-width:500px) {
  .mainSubTitleMobile{
    display:block;
  }

  .mainSubTitleWeb{
    display:none;
  }
}


  
